import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import { CONTENTFUL_TAGS } from "@/shared/contentful-constants.mjs";
import { getEntriesByTags } from "@/shared/contentful-fetches.mjs";

export default async function fetchArticlesByGame(
  gameSymbol: GameSymbol,
): Promise<void> {
  await getEntriesByTags(
    [CONTENTFUL_TAGS[GAME_SHORT_NAMES[gameSymbol]]],
    10,
    "id_contains_some",
  );
}
